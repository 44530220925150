import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  name: 'ProgressSteps',
  components: {},
  props: {
    completed: Boolean,
    currentStep: Number,
    numOfSteps: Number
  },
  data: function data() {
    return {
      stepper: null
    };
  },
  watch: {
    currentStep: function currentStep(step) {
      this.stepper = step;
    },
    stepper: function stepper(step) {
      this.$emit('change-step', step);
    }
  },
  created: function created() {
    this.stepper = this.currentStep;
  }
});