<template>
  <layout-3
    name="signup-step-2"
    :title="$t('signup.step.2.title')"
    :subtitle="$t('signup.step.2.subtitle')"
    :image="{ src: 'enter-name', width: 447, height: 364 }"
    hide-image-on-mobile
  >
    <v-form @submit.native.prevent>
      <v-text-field
        v-model="first"
        data-cy="firstNameInput"
        autocomplete="given-name"
        hide-details
        :label="$t('signup.step.2.label_first_name')"
        name="fname"
        filled
        :rules="[() => !!first || '']"
        class="mb-5"
        @update:error="compliant.first = !$event"
      />
      <v-text-field
        v-model="last"
        data-cy="lastNameInput"
        autocomplete="family-name"
        :label="$t('signup.step.2.label_last_name')"
        name="lname"
        filled
        :rules="[() => !!last || '']"
        @update:error="compliant.last = !$event"
      />
      <v-btn
        data-cy="submitNameBtn"
        :block="true"
        :disabled="!compliant.first || !compliant.last"
        color="info"
        x-large
        @click="submit"
      >
        {{ $t('global.next') }}
      </v-btn>
      <slot />
    </v-form>
  </layout-3>
</template>

<script lang="ts">
import Vue from 'vue'

import Layout3 from '~/components/layouts/Layout3.vue'

export default Vue.extend({
  components: {
    Layout3,
  },
  props: {
    initName: {
      type: Object as Vue.PropType<{ first: string; last: string }>,
      required: true,
    },
  },
  data() {
    return {
      first: '',
      last: '',
      compliant: {
        first: false,
        last: false,
      },
    }
  },
  mounted() {
    this.first = this.initName.first
    this.last = this.initName.last
    this.compliant.first = this.first !== ''
    this.compliant.last = this.last !== ''
  },
  methods: {
    submit(): void {
      if (!this.compliant.first || !this.compliant.last) return
      this.$emit('save', { first: this.first, last: this.last })
    },
  },
})
</script>
