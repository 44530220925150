<template>
  <layout-3
    name="signup-step-3"
    :title="$t('gtc.scenario_2.title')"
    :subtitle="$t('gtc.scenario_2.text')"
    :image="{ src: 'biz-terms', width: 328, height: 524 }"
    equal-width
    hide-image-on-mobile
  >
    <v-form @submit.native.prevent>
      <v-checkbox
        v-model="compliant"
        data-cy="privacyPolicyCheckbox"
        class="mt-0"
        on-icon="$vuetify.icons.checkbox_active"
        off-icon="$vuetify.icons.checkbox_inactive"
        :hide-details="true"
      >
        <template #label>
          <i18n path="gtc.scenario_2.checkbox.label_text" tag="div" for="gtc.scenario_2.checkbox.label_link_text">
            <a
              class="link"
              :href="$t('gtc.scenario_2.checkbox.label_link_url')"
              target="_blank"
              rel="noopener"
              @click.stop
              >{{ $t('gtc.scenario_2.checkbox.label_link_text') }}</a
            >
          </i18n>
        </template>
      </v-checkbox>
      <div v-if="showPrivacyPolicy" class="mt-8" v-html="$t('signup.privacy_policy')" />
      <v-btn
        data-cy="submitBtn"
        :disabled="!compliant || loading"
        :loading="loading"
        class="ma-0 mt-8"
        color="info"
        x-large
        block
        @click="submit"
      >
        {{ $t('signup.register_now') }}
      </v-btn>
      <slot />
    </v-form>
  </layout-3>
</template>

<script lang="ts">
import Vue from 'vue'

import Layout3 from '~/components/layouts/Layout3.vue'

export default Vue.extend({
  components: {
    Layout3,
  },
  provide() {
    return {
      headingBottomPaddingClass: 'pb-8',
    }
  },
  props: {
    showPrivacyPolicy: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      compliant: false,
    }
  },
  methods: {
    submit() {
      this.$emit('accept')
    },
  },
})
</script>

<style></style>
